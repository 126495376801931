/**
 * ScandiPWA - Progressive Web App for Magento
 *
 * Copyright © Scandiweb, Inc. All rights reserved.
 * See LICENSE for license details.
 *
 * @license OSL-3.0 (Open Software License ("OSL") v. 3.0)
 * @package scandipwa/scandipwa
 * @link https://github.com/scandipwa/scandipwa
 */

@import '../../../node_modules/@scandipwa/scandipwa/src/style/abstract/variables';
@import '../../../node_modules/@scandipwa/scandipwa/src/style/abstract/media';
@import '../../../node_modules/@scandipwa/scandipwa/src/style/abstract/button';
@import '../../../node_modules/@scandipwa/scandipwa/src/style/abstract/loader';
@import '../../../node_modules/@scandipwa/scandipwa/src/style/abstract/icons';
@import '../../../node_modules/@scandipwa/scandipwa/src/style/abstract/parts';

/* stylelint-disable csstools/use-logical */
/* stylelint-disable number-max-precision */
/* stylelint-disable no-duplicate-selectors */
/* stylelint-disable declaration-no-important */
/* stylelint-disable property-no-unknown */

.CategoryPage {
    @include desktop {
        max-width: 1170px;
        width: 100%;
        margin-inline: auto;
        padding-inline: 15px;
    }

    @media (max-width: 992px) {
        .CategoryProductList-Page {
            grid-template-columns: repeat(2, 1fr);
        }
    }

    @media (min-width: 1200px) {
        padding-inline: 0;
    }

    @media (min-width: 1600px) {
        max-width: 1405px;
        width: 100%;

        .CategoryProductList-Page {
            display: grid;
            grid-template-columns: repeat(5, 1fr);
            grid-auto-rows: max-content;
            grid-column-gap: 0.65rem;
            margin-block-start: 10px;

            &-Page {
                display: contents;
            }
        }
    }

    & ~ .Breadcrumbs {
        display: none;
    }
    // .CategoryPage-ProductListWrapper nav {
    //     display: none;
    // }

    .CategoryFilterOverlay-Heading {
        font-family: regular-bold,sans-serif;
        text-transform: uppercase;
        font-size: 20px;
        font-weight: 400;
        padding: 5px 10px;
        margin: 0;
    }

    .ProductConfigurableAttributes {
        padding: 0;
    }

    .vehiclePgae {
        .sidebar-additional {
            display: none;
        }

        .main_columns {
            display: block;
            
            .columns_product {
                max-width: 100%;
            }
        }
        
        .product-slider .child .ProductCard {
            border: none;
            margin-bottom: 0;
        }

        .expSlider {
            margin-bottom: 60px;

            h2 {
                text-align: center;
                font-size: 30px;
                font-family: Intro, sans-serif;
                margin-bottom: 25px;

                @include mobile {
                    font-size: 25px;
                }
            }
        }

        .CategoryProductList {
            padding-left: 0;
        }

        .CategoryPage-ItemsCount {
            padding-left: 0;
        }

        .product-slider {
            display: flex;
        }
    }

    .ProductList-ProductContact {
        text-align: center;
        font-size: 15px;
        margin-block-start: 30px;
        padding: 0 15px;

        .product-item-link {
            color: #333;

            &:hover {
                color: var(--text-red-color);
            }
        }

        .product-item-contact {
            display: block;
            margin-block-start: 15px;

            & a.product-item-link {
                color: #fff;

                &:hover {
                    color: #fff;
                }
            }
        }
    }

    .cat_wrap {
        .Breadcrumbs {
            margin-block-start: 3px;

            .ContentWrapper {
                padding: 0;
            }

            nav {
                padding-block-start: 0;
                border: none;
            }
        }
    }

    .pro_inner_top .ProductCard {
        &-PriceBadge {
            font-family: "Regular-Book", sans-serif;
            color: #444;
            font-size: 14px;
        }
    }

    &-FilterButtonPlaceholder {
        display: none;
    }

    .category_heading_veh {
        padding: 15px 10px;
        text-align: center;
        margin-top: -1px;
        // margin-left: 15px;

        .categoryName {
            font-family: intro, sans-serif;
            font-size: 30px;
            line-height: 40px;
            color: var(--h2-color);
            margin: 5px 0 5px;

            @include mobile {
                font-size: 20px;
                line-height: 24px;
            }
        }

        .categorySubTitle {
            font-family: intro, sans-serif;
            font-size: 36px;
            margin: 0;

            @include mobile {
                font-size: 24px;
                line-height: 24px;
            }
        }

        & + .CategoryPage-Miscellaneous {
            border-top: none;

            @include mobile {
                display: none;
            }
        }

        @include mobile {
            margin: 15px 0;
        }
    }

    &-LayoutWrapper {
        display: flex;
        align-items: center;
        padding-left: 18px;
        margin-left: auto;

        .CategorySort-Select {
            height: auto;
            margin-block-start: 0;
        }

        @include mobile {
            width: 100%;
            padding: 0;
            margin-block-end: 10px;

            div.CategorySort select {
                padding-left: 10px;
            }
        }
    }

    &-LayoutButtons {
        display: none;

        button {
            font-size: 0;
            width: 30px;
            height: 18px;
            margin-inline-end: 8px;

            &.grid {
                background-image: url(../../images/grid.png);
                background-position: 0 18px;

                &_isActive,
                &:hover {
                    background-position: 0 0;
                }
            }

            &.list {
                background-image: url(../../images/list.png);
                background-position: 0 18px;

                &_isActive,
                &:hover {
                    background-position: 0 0;
                }
            }
        }
    }

    &-ProductListWrapper {
        .Loader ~ {
            .CategoryProductList,
            .ProductList {
                display: none;
            }
        }
    }

    &-Wrapper {
        .CategoryName {
            display: block;
            border-top: 1px solid #e2e2e2;
            padding-block: 18px 10px;

            &:empty {
                display: none;
            }

            &Link {
                display: inline-block;
                background: #F5F5F5;
                border: 1px solid #D9D9D9;
                border-radius: 999px;
                padding: 8px 16px;
                font-size: 16px;
                font-family: regular-bold, sans-serif;
                font-weight: 300;
                color: #444;
                margin: 0 7px 8px 0;
                transition: all 0.2s linear 0s;
                text-transform: uppercase;

                &:hover {
                    background: #444;
                    color: #fff;
                    border-color: #444;
                }
            }

            @include mobile {
                margin-top: 10px;
                padding-block: 12px 6px;

                &Link {
                    font-size: 14px;
                    padding: 6px 10px;
                }
            }
        }

        @include mobile {
            .sidebar-additional {
                display: none;
            }
        }

        @include desktop {
            .CategoryProductList-Page {
                grid-column-gap: 0.65rem;
            }

            .CategoryFilterOverlay {
                // display: none;
            }

            &.ContentWrapper {
                display: block;
                padding-inline: 0;
            }

            .main_columns {
                display: flex;
                flex-direction: row-reverse;

                .CategoryProductList-Page_layout_list {
                    max-width: 100%;
                    margin-block-start: 1.2rem;

                    li.ProductCard {
                        display: flex;
                        width: 100%;
                        padding: 10px;

                        .pro-image {
                            min-width: 300px;
                            margin-inline-end: 20px;

                            @include tablet {
                                min-width: 200px;
                            }

                            .ProductCard-Link {
                                width: 100%;

                                .Image {
                                    width: 100%;
                                }
                            }
                        }

                        .product-item-details {
                            display: flex;
                            text-align: start;

                            .pro-left {
                                width: 70%;
                                padding-inline-end: 7%;

                                .ProductCard-Name {
                                    min-height: unset;
                                }

                                .ProductCard-SKU {
                                    font-family: regular-bold, sans-serif;
                                    color: gray;
                                    margin-block-start: 4px;
                                    display: block;
                                }

                                .more_info {
                                    display: none;
                                }

                                .ProductActions-Section_type_short {
                                    display: block;
                                    margin-block-start: 10px;
                                    font-size: 16px;
                                    font-family: regular-book, sans-serif;
                                    width: 100%;
                                    color: var(--text-light-black-color);
                                }
                            }

                            .pro-right {
                                text-align: end;
                                flex: 1;

                                .pro_inner_top {
                                    display: block;

                                    .pro-inner-wrap {
                                        padding: 0;

                                        .ProductCard-Link {
                                            width: auto;
                                            display: inline-block;
                                            margin-block-end: 10px;

                                            .more_info.btn {
                                                line-height: 22px;
                                                font-size: 12px;
                                                padding: 0 6px;
                                            }
                                        }
                                    }

                                    .price-box-text {
                                        font-size: 11px;
                                        margin-block-end: 10px;
                                    }

                                    button.Button.AddToCart,
                                    .ProductCard-Stock.outofstock {
                                        line-height: 22px;
                                        font-size: 12px !important;
                                        padding: 0 6px;
                                        width: auto;
                                        display: inline-block;
                                    }
                                }

                                .pro_inner_bot {
                                    display: block;

                                    .ProductCard-SKU {
                                        display: none;
                                    }

                                    button.ProductWishlistButton-Button {
                                        width: auto;
                                        margin-block-start: 12px;
                                    }
                                }
                            }
                        }
                    }
                }

                .columns_product {
                    flex: 1;
                    max-width: calc(100% - 220px);
                }

                .cat_wrap {
                    padding-left: 20px;
                }

                .sidebar-additional {
                    min-width: 220px;
                    width: 220px;
                    margin-block-end: 25px;

                    .SideMenu {
                        display: flex;
                        flex-direction: column;
                        border-top: 1px solid #e2e2e2;
                        padding-block-start: 18px;

                        .MenuOverlay-Menu {
                            background-color: #fff;
                            border: 1px solid #D9D9D9;
                        }

                        .MenuOverlay-Link,
                        .MenuOverlay-ItemCaption_type_sideMenu {
                            border-bottom: 1px solid #ececec;
                            padding: 6px 0 6px 10px;
                            font-size: 16px;
                            font-weight: 400;
                            color: #444;
                            width: 100%;
                            line-height: 1.15;
                            margin: 0;
                            font-family: regular-bold, sans-serif;
                            text-transform: uppercase;
                            cursor: pointer;
                            text-decoration: none;

                            &:hover {
                                color: var(--link-color);
                            }

                            .Selected {
                                //border-left: 2px solid var(--link-color);
                                background-color: #dcdcdc;
                                color: #444;
                                padding: 6px 0 6px 20px;
                                margin: -6px 0 -6px -20px;
                                // .MenuOverlay-ItemCaption::after {
                                //     content: "";
                                //     width: 0;
                                //     height: 0;
                                //     border-top: 5px solid transparent;
                                //     border-bottom: 5px solid transparent;
                                //     border-left: 6px solid var(--link-color);
                                //     margin-left: 3px;
                                //     display: inline-block;
                                // }
                            }
                        }

                        li.active-parent > .MenuOverlay-Link .Selected,
                        .Item > .MenuOverlay-Link .Selected {
                            background-color: transparent;

                            .MenuOverlay-ItemCaption::after {
                                display: none;
                            }
                        }

                        .MenuOverlay-ItemCaption_type_sideMenu {
                            border-bottom: none;
                            padding: 0;
                        }

                        .active-parent .MenuOverlay-ItemCaption_type_sideMenu {
                            color: var(--link-color);
                            border-bottom: none;
                        }

                        .MenuOverlay-Link {
                            padding: 6px 0 6px 10px;
                        }

                        .MenuOverlay-Item.HasChildMenuOpen.HasChildMenuOpen_type_subcategory
                        .MenuOverlay-Link {
                            padding-left: 20px;
                            
                            &.Selected {
                                pointer-events: none;
                            }
                        }

                        .Item > .MenuOverlay-Link:hover,
                        li.MenuOverlay-Item > .MenuOverlay-Link:hover {
                            // border-left: 2px solid var(--link-color);
                            // margin-left: -2px;
                        }

                        .Item {
                            &:hover {
                                svg {
                                    color: var(--link-color) !important;
                                }

                                .MenuOverlay-ItemCaption_type_sideMenu {
                                    color: var(--link-color);
                                }
                            }
                        }

                        .block-title-cat {
                            background: #444;
                            color: #fff;
                            font-size: 24px;
                            padding: 12px 5px;
                            margin: 0;
                            line-height: 1;
                            text-align: center;
                            border-bottom: 1px solid #e2e2e2;
                            text-transform: uppercase;
                            font-family: regular-bold, sans-serif;
                            font-weight: 300;
                            word-wrap: break-word;
                            order: -1;
                            margin-block-end: 10px;
                        }

                        .MenuOverlay-Item.HasChildMenuClosed_type_subcategory {
                            display: none;

                            &.active-parent {
                                display: block;
                            }
                        }

                        .MenuOverlay-Item.HasChildMenuClosed.active-parent {
                            background: transparent;

                            .MenuOverlay-Item.HasChildMenuClosed_type_subcategory.active-parent {
                                display: none;
                            }

                            .MenuOverlay-ItemCaption_type_sideMenu {
                                color: #333;
                            }
                        }

                        li::before {
                            display: none;
                        }

                        li.MenuOverlay-Item {
                            margin-block-end: 0;

                            &.active-parent {
                                background: #f5f5f5;
                            }
                        }

                        .fr_btn-show {
                            position: absolute;
                            right: 10px;
                            inset-block-start: 5px;
                            cursor: pointer;

                            svg {
                                transform: rotate(-90deg);
                                transition: transform 0.3s ease-in-out;
                            }
                        }

                        .active-parent .fr_btn-show {
                            //border-left: 9px solid transparent;
                            //border-right: 9px solid transparent;
                            //border-top: 10px solid var(--link-color);
                            //top: 10px;
                            // svg {
                            //     transform: rotate(0deg);
                            //     color: var(--link-color);
                            // }
                        }

                        .Item.HasChildMenuOpen {
                            background: #f5f5f5;

                            a.MenuOverlay-Link {
                                border-left: 2px solid var(--link-color);
                                margin-left: -2px;
                                border-bottom: 1px solid #eaeaea;
                            }

                            .MenuOverlay-ItemCaption_type_sideMenu {
                                border-bottom: none;
                            }

                            .fr_btn-show {
                                //border-left: 9px solid transparent;
                                //border-right: 9px solid transparent;
                                //border-top: 10px solid var(--link-color);
                                //top: 8px;
                            }

                            .MenuOverlay-ItemCaption_type_sideMenu {
                                color: var(--link-color);
                            }

                            .fr_btn-show {
                                svg {
                                    transform: rotate(0deg);
                                    transition: transform 0.3s ease-in-out;
                                }
                            }
                        }

                        .MenuOverlay-Item {
                            .HasChildMenuOpen_type_subcategory {
                                a {
                                    border-left: 0px solid var(--link-color) !important;
                                    margin-left: 0px !important;
                                }
                            }
                        }
                    }
                }
            }
            // .category_heading {
            //     display: none;
            // }

            .count_sort_wrap {
                display: flex;
                padding: 18px 0;
                align-items: center;

                p {
                    padding-block-end: 0;
                }
            }

            .CategoryDetails {
                display: flex;
                padding-block-end: 2px;
                // background: transparent;
                // @include desktop {
                //     min-height: 172px;
                // }
                @include tablet {
                    min-height: auto;
                }

                &-Description {
                    padding: 0;
                    // order: 1;
                    align-self: center;
                    margin-left: auto;
                }
            }

            .pro_inner_top {
                display: flex;
                align-items: center;
                justify-content: space-around;
                flex-direction: column;
                min-height: 80px;
            }

            .pro_inner_bot {
                display: flex;
                align-items: baseline;
                justify-content: space-around;
                margin-top: 8px;
            }

            .CategoryProductList {
                padding-block-end: 5px;
                min-height: unset;
                padding-left: 15px;
            }

            .ProductCard-Stock.outofstock {
                padding: 0 15px;
                margin-block-end: 0;
                white-space: nowrap;
            }
        }

        .CategoryProductList {
            nav,
            .CategoryPagination {
                min-height: 80px;
                margin-block-end: 20px;

                &:first-child {
                    // display: none;
                }
            }
        }

        @include mobile {
            .CategoryDetails-Picture {
                display: none;
            }
        }

        @include desktop {
            .CategoryDetails-Picture {
                width: 100vw;
                position: relative;
                inset-inline-start: calc(-50vw + 50%);

                img {
                    position: static;
                    display: block;
                    height: 140px;
                    object-fit: cover;
                    object-position: 100% 50%;
                }

                .img-overlay {
                    position: absolute;
                    z-index: 1;
                    background-color: rgba(0, 0, 0, 0.3);
                    display: block;
                    width: 100%;
                    height: 140px;
                    inset-block-start: 0;
                    -moz-box-shadow: inset 0 -10px 10px -10px rgba(0, 0, 0, 0.3);
                    -webkit-box-shadow: inset 0 -10px 10px -10px
                        rgba(0, 0, 0, 0.3);
                    box-shadow: inset 0 -10px 15px -10px rgba(0, 0, 0, 0.3);
                }
            }

            .category_heading {
                color: #fff;
                display: block;

                h1 {
                    font-family: intro, sans-serif;
                    font-weight: 400;
                    line-height: 1.25;
                    color: #000;
                    font-size: 24px;
                }
            }
        }

        @include mobile {
            padding-inline: 15px;
        }

        h1.categoryName {
            @include mobile {
                font-size: 24px;
                margin-block: 15px 10px;
                text-align: left;
                display: inline-block;
                width: 100%;
                line-height: 1.2;
            }
        }

        button.Button {
            margin: 0 0 10px;
            min-width: unset;
            width: 100%;

            @include desktop {
                margin: 0;
            }

            &.AddToCart {
                background-color: var(--text-red-color);
                font-size: 14px;
                line-height: 30px;
                height: auto;
                border-radius: 999px;

                &:hover {
                    background-color: var(--text-red-hover-color);
                    border-radius: 999px;
                    padding: 0px 15px;
                }

                @include desktop {
                    font-size: 15px;
                    line-height: 30px;
                    padding: 0px 15px;
                    white-space: nowrap;
                }
            }
        }

        .outofstock {
            margin: 0 0 10px;
        }

        .ProductCard-Shop {
            background-color: var(--text-red-color);
            font-size: 15px;
            width: 100%;

            @include mobile {
                line-height: 34px;
                margin: 0 0 10px;
            }
        }
    }

    &-ItemsCount {
        color: #666669;
        font-size: 12px;
        text-align: center;
        display: inline-block;

        @include desktop {
            display: flex;
            align-items: center;
            margin-bottom: 0 !important;
            padding-left: 15px;
        }
    }

    &-Filter {
        min-height: 30px;
        width: 40px;
        border: 2px solid rgba(0, 0, 0, 0.2);
        padding: 0 5px;
        font-size: 0;
        margin-left: 7px;
        display: none;

        &::before,
        &::after {
            content: "";
            position: absolute;
            left: calc(90% - 68px);
            top: calc(50% - 5px);
        }

        &::before {
            right: 70%;
            width: 11px;
            height: 8px;
            background-color: var(--header-color);
            clip-path: polygon(
                5px 8px,
                11px 0,
                0 0,
                5px 8px,
                5px 6px,
                2px 1.5px,
                8.6px 1.5px,
                5px 6px
            );
        }

        &::after {
            height: 5px;
            width: 1px;
            background-color: var(--header-color);
            transform: translate3d(5px, 6px, 0);
        }
    }

    &-Miscellaneous {
        display: flex;
        flex-wrap: wrap;
        align-items: flex-end;

        @include mobile {
            padding: 15px 0 5px;
        }

        @include desktop {
            border-top: 1px solid #e2e2e2;
            padding-block-start: 10px;
        }
    }

    .CategoryProductList + .CategorySort {
        margin-block-start: 8px;

        @include desktop {
            margin-block-end: 20px;
            width: 210px;
        }
    }

    .CategoryProductList-Page {
        @include mobile {
            grid-column-gap: 2%;
            padding-inline: 0;
        }
    }

    .ProductCard {
        background: #ededed;
        border: 1px solid #e2e2e2;
        border-bottom: 2px solid #e2e2e2;
        overflow: hidden;
        margin-block-end: 10px;
        position: relative;
        padding: 4px 4px 10px;

        @include desktop {
            &:hover {
                border-bottom-color: var(--link-color);
            }
        }

        a:hover {
            text-decoration: none;

            .ProductCard-Name {
                color: var(--link-color);
            }
        }

        .ProductCard-Name {
            @include desktop {
                font-family: regular-bold, sans-serif;
                font-size: 16px;
                line-height: 1.1;
                margin-block-end: 7px;

                > div {
                    margin-top: 2px;
                    // font-family: regular-book, sans-serif;
                }
            }
        }

        .ProductCard-Picture {
            border-radius: 4px;

            img {
                border-radius: 4px;
                left: 0;
            }
        }

        @include desktop {
            padding: 5px 5px 10px;
        }
    }

    .ProductWishlistButton-Button {
        height: auto;
    }

    .CategoryProductList {
        inset-block-start: 0;
    }

    .CategorySort {
        min-width: 140px;

        li[id~="sku"] {
            display: none;
        }

        select {
            border: 2px solid rgba(0, 0, 0, 0.2);
            padding: 0 33px 0 5px;
            height: 32px;
            font-size: 13px;
            color: #454545;
            position: static;
            opacity: 1;
        }

        &-Select label:first-of-type {
            display: none;
        }

        @include mobile {
            min-width: unset;
            flex: 1;
        }
    }

    .Category {
        &Pagination {
            margin-block-start: 0;
            // margin-bottom: 0;
            // opacity: 0;

            &-ListItem {
                margin: 0 6px;

                .CategoryPaginationLink {
                    margin: 0;
                }
            }

            &Link {
                &:hover,
                &:focus {
                    border-color: var(--color-pagination-link-active-text);

                    .ChevronIcon {
                        fill: var(--color-pagination-link-active-text);
                    }
                }

                &_isCurrent {
                    background: var(--color-pagination-link-active-text);
                    color: #fff !important;
                }
            }
        }
    }

    &-PromoBanner {
        position: relative !important;
        background: linear-gradient(#0007, #0000),#123;
        border: 0px #232323 solid;
        color: #fff;
        padding: 12px 15px;
        margin: 10px 15px;
        font-family: regular-bold, sans-serif;
        font-size: 20px;
        border-radius: 3px;

        strong {
            font-family: intro, sans-serif;
            margin-inline-end: 5px;
        }

        span {
            font-family: regular-book, sans-serif;
        }

        &-terms {
            font-size: 16px;
            text-decoration: underline;
            color: #fff;
            opacity: 0.75;
            transition: 0.3s;
            font-weight: normal;

            &:hover {
                opacity: 1;
                color: #fff;
            }
        }

        @include mobile {
            margin: 0 0 15px 0;
        }
    }

    .category-promo {
        margin: 10px 0 0 15px;

        @include mobile {
            margin: 0 0 10px 0;
        }
        
        img {
            height: auto;
            border-radius: 5px;
        }
    }
}

div .ProductCard-Shop {
    background-color: var(--text-red-color);
    color: #fff !important;
    border-radius: 999px;
}

.categorySubTitle {
    margin-block: -0.6rem 10px;
    font-size: 24px;
    text-align: center;
    display: inline-block;
    width: 100%;
    color: var(--text-red-color);
}

.pro-left .ProductCard-SKU,
.pro-inner-wrap .ProductCard-Link,
.pro-left .ProductActions-Section_type_short {
    display: none;
}
