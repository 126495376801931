/**
 * ScandiPWA - Progressive Web App for Magento
 *
 * Copyright © Scandiweb, Inc. All rights reserved.
 * See LICENSE for license details.
 *
 * @license OSL-3.0 (Open Software License ("OSL") v. 3.0)
 * @package scandipwa/scandipwa
 * @link https://github.com/scandipwa/scandipwa
 */

@import '../../../node_modules/@scandipwa/scandipwa/src/style/abstract/variables';
@import '../../../node_modules/@scandipwa/scandipwa/src/style/abstract/media';
@import '../../../node_modules/@scandipwa/scandipwa/src/style/abstract/button';
@import '../../../node_modules/@scandipwa/scandipwa/src/style/abstract/loader';
@import '../../../node_modules/@scandipwa/scandipwa/src/style/abstract/icons';
@import '../../../node_modules/@scandipwa/scandipwa/src/style/abstract/parts';

/* stylelint-disable csstools/use-logical */
/* stylelint-disable number-max-precision */
/* stylelint-disable declaration-no-important */

.CategoryFilterOverlay {
    &.Overlay {
        position: static;
        height: auto;
        opacity: 1;
        pointer-events: all;
    }

    &-Heading {
        font-family: regular-bold, sans-serif;
        text-transform: uppercase;
        font-size: 20px;
        padding: 5px 10px;
    }

    .ResetAttributes-Title {
        font-family: regular-bold, sans-serif;
        text-transform: uppercase;
        padding: 3px 10px;
        font-size: 20px;
        font-weight: 400;
        margin: 8px 0 0;
    }

    .CategoryFilterOverlay-ResetButton {
        margin-block-end: 22px;
    }

    .ExpandableContentShowMore-ExpandableChildren {
        height: auto !important;
    }

    &-Attributes .ExpandableContent {
        font-family: regular-bold, sans-serif;
        text-transform: uppercase;
        font-size: 17px;
        background: #f5f5f5;
        border: 1px solid #D9D9D9;
        margin-block-end: 10px;
        padding: 0;

        &:last-of-type {
            border-bottom: 1px solid #D9D9D9;
        }

        &-Heading {
            color: #444;
            font-size: 16px;
            font-weight: 400;
            text-transform: uppercase;
            padding: 6px 0 6px 10px;
            border-bottom: 1px solid #D9D9D9;
            width: 100%;
            cursor: pointer;
            background-color: #fff;
        }

        &-Content {
            margin-block-start: 0;
        }

        .ProductConfigurableAttributes-DropDownList {
            margin: 0;
            padding: 6px 0 6px 10px;
        }

        .ProductAttributeValue-Text {
            background-color: transparent;
            padding-block-end: 0;
            min-height: auto;

            span {
                color: #444;
                font-size: 16px;
                font-weight: 400;
            }
        }

        .ExpandableContent {
            &-Button {
                &::before {
                    position: absolute;
                    right: 10px;
                    inset-block-start: 10px;
                    z-index: 1;
                    cursor: pointer;
                    color: var(--link-color);
                    content: url("data:image/svg+xml; utf8, <svg aria-hidden='true' focusable='false' data-prefix='fas' data-icon='chevron-down' class='svg-inline--fa fa-chevron-down' role='img' xmlns='http://www.w3.org/2000/svg' viewBox='0 0 448 512'><path fill='currentColor' d='M201.4 406.6c12.5 12.5 32.8 12.5 45.3 0l192-192c12.5-12.5 12.5-32.8 0-45.3s-32.8-12.5-45.3 0L224 338.7 54.6 169.4c-12.5-12.5-32.8-12.5-45.3 0s-12.5 32.8 0 45.3l192 192z'></path></svg>");
                    display: block;
                    width: 12.25px;
                    height: 15px;
                    transition: transform 0.3s ease-in-out;
                    transform: rotate(-90deg);
                    filter: brightness(0.8);
                    fill: red;
                }

                &_isContentExpanded {
                    &::before {
                        transform: rotate(0deg);
                        color: var(--link-color);
                    }

                    .ExpandableContent-Heading {
                        color: var(--link-color);
                        background-color: transparent;
                    }
                }

                .ExpandableContent-Heading {
                    &:hover {
                        color: var(--link-color);
                    }
                }
            }

            &-Content {
                display: none;

                &_isContentExpanded {
                    display: block;
                }

                .input-control {
                    // display: none;
                }

                .ProductAttributeValue-Text_isSelected {
                    // background-color: #bbb;
                    // color: #fff;
                    // padding: 6px 0 6px 20px;
                    // margin: -6px 0 -6px -20px;
                    // .Field-CheckboxLabel {
                    //     order: 0;
                    // }
                    // &::after {
                    //     content: "";
                    //     width: 0;
                    //     height: 0;
                    //     border-top: 5px solid transparent;
                    //     border-bottom: 5px solid transparent;
                    //     border-left: 6px solid var(--link-color);
                    //     margin-left: 3px;
                    //     display: inline-block;
                    // }
                }
            }
        }
    }
}
