/**
 * ScandiPWA - Progressive Web App for Magento
 *
 * Copyright © Scandiweb, Inc. All rights reserved.
 * See LICENSE for license details.
 *
 * @license OSL-3.0 (Open Software License ("OSL") v. 3.0)
 * @package scandipwa/scandipwa
 * @link https://github.com/scandipwa/scandipwa
 */

@import '../../../node_modules/@scandipwa/scandipwa/src/style/abstract/variables';
@import '../../../node_modules/@scandipwa/scandipwa/src/style/abstract/media';
@import '../../../node_modules/@scandipwa/scandipwa/src/style/abstract/button';
@import '../../../node_modules/@scandipwa/scandipwa/src/style/abstract/loader';
@import '../../../node_modules/@scandipwa/scandipwa/src/style/abstract/icons';
@import '../../../node_modules/@scandipwa/scandipwa/src/style/abstract/parts';

/* stylelint-disable csstools/use-logical */
/* stylelint-disable declaration-no-important */

.CategoryDetails {
    display: grid;
    grid-template-columns: auto minmax(100px, 15rem);
    background-color: var(--category-details-background);
    font-family: regular-book, sans-serif;

    @include mobile {
        display: block;
    }

    .category_heading {
        @include mobile {
            text-align: left;
            width: 100%;
        }
    }

    &-Picture {
        mix-blend-mode: multiply;
        height: 100%;
        background: none;

        &.no_image {
            min-height: unset;

            & + .CategoryDetails-Description {
                display: none;
            }
        }
    }

    &-Heading {
        font-size: 14px;
        text-transform: uppercase;
        text-decoration: underline;
        display: flex;
        cursor: pointer;

        @include mobile {
            margin-left: 0;
        }

        .TextPlaceholder {
            margin-left: 2px;
        }

        svg {
            margin-left: 5px;
            font-size: 14px;
        }
    }

    .cat-desc-text-float {
        display: none;
        background: #fff;
        padding: 15px 20px;
        position: absolute;
        width: 500px;
        box-shadow: 0 15px 20px rgba(0, 0, 0, 0.3);
        z-index: 9;
        right: 0;
        // margin-left: -250px;
        inset-block-start: 15px;
        font-size: 16px;
        color: #333;
        text-transform: none;
        text-align: start;
        line-height: 1.2;

        p:last-child {
            margin: 0;
        }
    }

    &-Description {
        display: flex;
        flex-direction: column;
        justify-content: center;
        font-size: 14px;
        font-weight: 300;
        padding: 2rem;
        text-align: center;
        z-index: 2;

        &:hover {
            .cat-desc-text-float {
                @include desktop {
                    display: block;
                }
            }
        }

        @include mobile {
            padding: 0;
            margin-left: 0;

            .cat-desc-text-float {
                width: auto;
                top: 10px;
                position: relative;
                padding: 15px 25px;

                &.showDesc {
                    display: block;
                }
            }
        }

        .clsBtn {
            position: absolute;
            right: 5px;
            top: 6px;

            .CloseIcon {
                width: 28px;
                height: 28px;
            }

            @include desktop {
                display: none;
            }
        }
    }

    // p {
    //     max-width: 500px;
    //     overflow: hidden;
    //     text-overflow: ellipsis;
    //     white-space: nowrap;
    //     height: 2rem;

    //     @include mobile {
    //         max-width: 200px;
    //     }
    // }

    &-Vehicle {
        display: none;

        form {
            padding: 0 !important;
        }
    }
}
